import React from "react";
import styled from "styled-components";

const Text = styled.span`
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 250;
  line-height: 60px;
  width:500px;
  margin-top: 40px;
  @media (max-width: 768px) {
    line-height: 25px; 
    font-size: 24.5px;
    margin-top: 0px;
    width:auto;
  }
`;
const TextBold = styled.span`
  color: #fff;
  font-family: DM Sans;
  font-size:  60px
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  margin-top: 40px;
  @media (max-width: 768px) {
    line-height: 25px;
    font-size: 23.5px;
    margin-top: 0px;

  }
`;
const OutlineButton = styled.button`
  border-radius: 100px;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(2.5px);
  color: #fff;
  text-align: center;
  font-feature-settings: "case" on;
  font-family: DM Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-left:10px;
  }
`;
const OutlineButtonLinkedin = styled.button`
  border-radius: 76.699px;
  border: 1px solid #fff;
  display: flex;
  width: 80px;
  background: transparent;
  height: 30px;
  padding: 7.67px 15.34px;
  justify-content: center;
  align-items: center;
  gap: 3.835px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 13.039px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.313px;
  @media (max-width: 768px) {
   display:none

  }
`;
const OutlineButtonLinkedin2 = styled.button`
display:none;
  @media (max-width: 768px) {
    border-radius: 100px;
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(2.5px);
    color: #fff;
    text-align: center;
    font-feature-settings: "case" on;
    font-family: DM Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    letter-spacing: -0.408px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 40px;
    margin-right:5px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;
const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  width: 70%;
  max-width:800px;
  justify-content: center;
  @media (max-width: 768px) {
    width:82%;
  }
`;
const StartDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10%;
  width: 70%;
  justify-content: center;
`;
const EndDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15%;
  width: 100%;
  justify-content: end;
`;
const FooterText = styled.p`
  color: #fff;
  text-align: center;
  font-feature-settings: "case" on;
  font-family: DM Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; 
  letter-spacing: -0.408px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px; 


  }
`;
const RedirectText = styled.a`
  color: #fff;
  text-align: center;
  font-feature-settings: "case" on;
  font-family: DM Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.408px;
  text-decoration: none;
`;
const LinkedinText = styled.a`
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-feature-settings: "case" on;
  font-family: DM Sans;
  font-size: 13.039px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.313px;
`;
const Landing = () => {
  return (
    <Container>
      <StartDiv>
        <OutlineButtonLinkedin>
          <LinkedinText
            target="_blank"
            href="https://www.linkedin.com/company/semionbio"
          >
            Linkedin
          </LinkedinText>
        </OutlineButtonLinkedin>
      </StartDiv>
      <CenterDiv>
        <svg
          width="50%"
          height="30%"
          viewBox="0 0 400 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6_987)">
            <path
              d="M170.496 75.4474C164.958 66.3685 166.435 54.6711 174.056 47.2501L210.621 11.658L199.136 0.0132141L162.479 35.5395C149.135 48.4606 146.999 69.079 157.389 84.4606L163.547 93.579L174.742 82.408L170.496 75.4606V75.4474Z"
              fill="white"
            />
            <path
              d="M238.985 42.7237L218.085 19.25L182.482 52.6053C176.562 58.1579 175.454 67.1447 179.871 73.9605L181.493 76.4605L218.269 42.1974L225.799 50.921C235.49 62.1579 234.528 79.0263 223.61 89.0789L189.339 120.618L199.136 131.908L236.176 99.0921C252.355 84.1974 253.608 59.1316 238.985 42.7105V42.7237Z"
              fill="white"
            />
            <path
              d="M222.238 60L219.087 54.7237L170.364 101.961L180.715 112.921L219.904 76.829C224.52 72.4474 225.482 65.4737 222.225 60.0132L222.238 60Z"
              fill="white"
            />
            <path
              d="M49.8302 206.118C46.5337 204.132 42.7757 202.447 38.5429 201.066C35.1277 199.934 32.0158 198.895 29.2072 197.961C26.3986 197.026 23.9459 196 21.823 194.855C19.7132 193.724 18.1045 192.355 17.0101 190.776C15.9156 189.197 15.3618 187.105 15.3618 184.513C15.3618 181.921 15.9684 179.684 17.1947 177.816C18.4078 175.947 20.122 174.526 22.3241 173.553C24.5261 172.579 27.0051 172.132 29.761 172.211C33.0971 172.211 35.9057 172.882 38.1737 174.224C40.4549 175.566 42.1559 177.289 43.3031 179.395C44.4371 181.5 45.0173 183.658 45.0173 185.842H57.9529C57.9529 181.053 56.7793 176.882 54.419 173.303C52.0587 169.737 48.8017 166.934 44.6613 164.908C40.5077 162.882 35.6684 161.868 30.1434 161.868C24.6184 161.868 19.6736 162.868 15.5596 164.855C11.4455 166.842 8.25449 169.605 5.98649 173.132C3.70529 176.658 2.57129 180.816 2.57129 185.605C2.57129 191.211 4.21955 195.75 7.51607 199.237C10.8126 202.724 15.8365 205.566 22.5878 207.763C25.9239 208.895 29.1149 209.961 32.1609 210.934C35.2069 211.908 37.8968 213.026 40.2176 214.276C42.5383 215.539 44.3844 217.132 45.7689 219.079C47.1535 221.026 47.8391 223.5 47.8391 226.5C47.8391 229.092 47.193 231.395 45.8876 233.382C44.5822 235.368 42.6966 236.934 40.2176 238.066C37.7386 239.197 34.7849 239.776 31.3697 239.776C27.3875 239.776 24.0251 239.026 21.3087 237.526C18.5792 236.026 16.509 234 15.0849 231.434C13.6608 228.882 12.9092 226.053 12.8301 222.974H0C0 228.408 1.30542 233.158 3.90308 237.211C6.50074 241.263 10.1665 244.408 14.8871 246.645C19.6077 248.882 25.0931 249.987 31.3565 249.987C37.6199 249.987 43.1449 248.829 47.4567 246.513C51.7686 244.197 55.0256 241.224 57.2144 237.566C59.4165 233.908 60.511 229.974 60.511 225.763C60.511 220.895 59.5484 216.895 57.6496 213.776C55.7376 210.658 53.1399 208.092 49.8434 206.105L49.8302 206.118Z"
              fill="white"
            />
            <path
              d="M114.917 189.75C110.605 187.066 105.436 185.737 99.4231 185.737C93.4103 185.737 88.1226 187.092 83.5602 189.816C79.011 192.539 75.4508 196.303 72.8795 201.132C70.3214 205.96 69.0424 211.618 69.0424 218.118C69.0424 224.618 70.3214 230.013 72.8795 234.789C75.4376 239.579 78.9979 243.316 83.5602 245.987C88.1094 248.671 93.3179 250 99.1726 250C103.972 250 108.218 249.145 111.924 247.447C115.629 245.737 118.728 243.408 121.259 240.447C123.778 237.487 125.571 234.145 126.626 230.408H114.429C113.216 233.329 111.343 235.645 108.812 237.342C106.293 239.053 103.076 239.895 99.1726 239.895C95.9156 239.895 92.9092 239.105 90.1401 237.526C87.371 235.947 85.1558 233.592 83.4943 230.46C82.057 227.763 81.2395 224.539 81.0417 220.776H128.195C128.274 219.645 128.34 218.605 128.38 217.671C128.419 216.737 128.446 215.868 128.446 215.053C128.446 209.697 127.272 204.803 124.912 200.382C122.552 195.96 119.215 192.408 114.904 189.724L114.917 189.75ZM81.1999 212.145C81.5296 209.25 82.2944 206.75 83.5075 204.658C85.1689 201.776 87.3842 199.592 90.1533 198.079C92.9224 196.579 95.9683 195.829 99.3044 195.829C104.025 195.829 107.968 197.276 111.132 200.145C114.31 203.026 116.011 207.026 116.262 212.132H81.1999V212.145Z"
              fill="white"
            />
            <path
              d="M222.871 190.171C219.298 188.184 215.025 187.184 210.068 187.184C205.914 187.184 201.998 188.197 198.292 190.224C194.587 192.25 191.647 195.053 189.444 198.618C188.218 196.105 186.636 193.987 184.684 192.289C182.733 190.579 180.491 189.303 177.973 188.46C175.454 187.605 172.645 187.184 169.56 187.184C165.499 187.184 161.833 188.079 158.576 189.868C155.319 191.658 152.721 194.132 150.77 197.289L149.913 188.645H138.929V250H151.126V217.382C151.126 213.158 151.811 209.592 153.196 206.671C154.58 203.75 156.453 201.513 158.813 199.974C161.174 198.434 163.771 197.658 166.619 197.658C171.089 197.658 174.491 199.118 176.799 202.039C179.12 204.961 180.28 209.263 180.28 214.947V250H192.477V217.382C192.477 213.158 193.15 209.592 194.495 206.671C195.84 203.75 197.686 201.513 200.046 199.974C202.406 198.434 205.083 197.658 208.103 197.658C212.573 197.658 215.975 199.118 218.282 202.039C220.603 204.961 221.764 209.263 221.764 214.947V250H233.842V213.724C233.842 207.882 232.906 202.987 231.033 199.053C229.161 195.118 226.431 192.158 222.858 190.171H222.871Z"
              fill="white"
            />
            <path
              d="M253.489 161.868C251.129 161.868 249.204 162.579 247.701 164C246.197 165.421 245.446 167.224 245.446 169.421C245.446 171.618 246.197 173.566 247.701 175.026C249.204 176.487 251.129 177.224 253.489 177.224C255.85 177.224 257.683 176.487 259.225 175.026C260.768 173.566 261.546 171.697 261.546 169.421C261.546 167.145 260.768 165.421 259.225 164C257.683 162.579 255.771 161.868 253.489 161.868Z"
              fill="white"
            />
            <path
              d="M259.594 188.645H247.397V250H259.594V188.645Z"
              fill="white"
            />
            <path
              d="M317.903 189.75C313.354 187.066 308.146 185.737 302.291 185.737C296.436 185.737 291.307 187.079 286.679 189.75C282.037 192.434 278.385 196.184 275.695 201.013C273.005 205.842 271.673 211.5 271.673 218C271.673 224.5 272.992 229.908 275.642 234.737C278.279 239.566 281.905 243.316 286.494 246C291.083 248.684 296.278 250.013 302.054 250.013C307.829 250.013 313.156 248.671 317.785 246C322.426 243.316 326.052 239.592 328.703 234.803C331.34 230.013 332.672 224.382 332.672 217.882C332.672 211.382 331.353 205.75 328.703 200.96C326.052 196.171 322.466 192.434 317.903 189.763V189.75ZM317.719 229.855C316.057 233.066 313.855 235.474 311.126 237.092C308.396 238.711 305.377 239.526 302.041 239.526C298.704 239.526 295.922 238.711 293.193 237.092C290.463 235.474 288.274 233.053 286.6 229.855C284.925 226.645 284.094 222.658 284.094 217.868C284.094 213.079 284.925 209.079 286.6 205.882C288.261 202.671 290.476 200.263 293.245 198.645C296.014 197.026 299.021 196.21 302.278 196.21C305.535 196.21 308.515 197.026 311.244 198.645C313.974 200.263 316.15 202.684 317.772 205.882C319.393 209.092 320.211 213.079 320.211 217.868C320.211 222.658 319.38 226.658 317.706 229.855H317.719Z"
              fill="white"
            />
            <path
              d="M397.073 198.934C395.121 195 392.392 192.053 388.897 190.105C385.403 188.158 381.21 187.184 376.331 187.184C371.452 187.184 367.589 188.263 364.015 190.408C360.442 192.566 357.673 195.461 355.721 199.105L354.983 188.632H343.999V249.987H356.196V217.237C356.196 213.013 356.947 209.447 358.451 206.526C359.954 203.605 361.971 201.395 364.49 199.895C367.008 198.395 369.857 197.645 373.034 197.645C377.834 197.645 381.487 199.105 384.018 202.026C386.537 204.947 387.803 209.25 387.803 214.934V249.987H400V213.71C400 207.789 399.024 202.855 397.073 198.921V198.934Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6_987">
              <rect width="400" height="250" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Text>
          <TextBold> Restoring crops' ability to defend against pests.</TextBold>
        </Text>
        <div style={{display:'flex',
      alignItems:'center',justifyContent:'center',width:'100%'}}>
        <OutlineButtonLinkedin2>
          <RedirectText
            target="_blank"
            href="https://www.linkedin.com/company/semionbio"
          >
            Linkedin
          </RedirectText>
        </OutlineButtonLinkedin2>   
             <OutlineButton>
          <RedirectText href="mailto:emilio@semionbio.com">
            Connect with us{" "}
            <svg
              style={{ marginBottom: "-3px", marginLeft: "5px" }}
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.33008 8.94922C9.33008 9.14453 9.26172 9.31055 9.11523 9.44727L1.3125 16.8789C1.17578 17.0059 1.01953 17.0742 0.824219 17.0742C0.453125 17.0742 0.150391 16.791 0.150391 16.4004C0.150391 16.2148 0.228516 16.0488 0.345703 15.9316L7.67969 8.94922L0.345703 1.9668C0.228516 1.83984 0.150391 1.67383 0.150391 1.48828C0.150391 1.10742 0.453125 0.814453 0.824219 0.814453C1.01953 0.814453 1.17578 0.882812 1.3125 1.00977L9.11523 8.45117C9.26172 8.58789 9.33008 8.75391 9.33008 8.94922Z"
                fill="white"
              />
            </svg>
          </RedirectText>
        </OutlineButton>
        </div>
      </CenterDiv>
      <EndDiv>
        <FooterText>© 2024 Semion — All rights reserved.</FooterText>
      </EndDiv>
    </Container>
  );
};

export default Landing;
